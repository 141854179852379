<template>
  <div class="d-inline">
    <div
      @click="openModal = true"
      class="ma-0 pa-0 d-inline"
    >
      <slot>
        <b-button
          v-if="edit"
          variant="success"
          block
        >
          <b-icon icon="pencil"></b-icon>
          Edit Company
        </b-button>
        <b-button
          v-else
          variant="success"
          block
        >
          <b-icon icon="plus"></b-icon>
          Add Company
        </b-button>
      </slot>
    </div>

    <b-modal
      v-model="openModal"
      id="addcompany"
      :title="(edit) ? 'Edit Company' : 'Add Company'"
      @hidden="reset()"
      @shown="init()"
      @ok="createCompany"
      ok-variant="primary"
      :busy="loading"
      :ok-disabled="isSaveDisabled"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-overlay :show="loading">
        <b-row>
          <b-col
            cols="12"
            lg="6"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Name *"
                  label-align="left"
                >
                  <b-input v-model="company.name"></b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col>
                <OrganizationTypeAhead
                  :selectedOrganizationId="company.organization_id"
                  @selection="setOrganization"
                  @newEntry="setOrganization"
                ></OrganizationTypeAhead>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Industry"
                  label-align="left"
                >
                  <b-form-select
                    v-model="company.industry_id"
                    @change="resetBucket()"
                    :options="industries"
                    text-field="name"
                    value-field="id"
                  >
                    <template #first>
                      <b-select-option :value="null">-- Select One --</b-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Industry Revenue Bucket"
                  label-align="left"
                >
                  <b-form-select
                    v-model="company.industryrevenuebucket_id"
                    :options="bucketsForIndustry"
                  >
                    <template #first>
                      <b-select-option :value="null">-- Select One --</b-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Company Status"
                  label-align="left"
                >
                  <b-form-select
                    v-model="company.companystatus_id"
                    :options="statuses"
                    text-field="name"
                    value-field="id"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Bookkeeper"
                  label-align="left"
                >
                  <b-form-select
                    v-model="company.bookkeeper_id"
                    :options="bookkeepers"
                    value-field="id"
                  >
                    <template #first>
                      <b-select-option :value="null">-- Select One --</b-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Data Entry"
                  label-align="left"
                >
                  <b-form-select
                    v-model="company.dataentry_id"
                    :options="dataentry"
                    value-field="id"
                  >
                    <template #first>
                      <b-select-option :value="null">-- Select One --</b-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Frequency"
                  label-align="left"
                >
                  <b-form-select
                    v-model="company.companytype_id"
                    :options="frequencies"
                    text-field="type"
                    value-field="id"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Priority"
                  label-align="left"
                >
                  <b-form-select
                    v-model="company.companypriority_id"
                    :options="priorities"
                    @change="setDayOfMonth"
                    text-field="name"
                    value-field="id"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Day of month Tickets Due"
                  label-align="left"
                  description="Please make this between 2 and 28"
                >
                  <b-form-input
                    type="number"
                    min="2"
                    max="28"
                    v-model="company.defaultdue"
                    onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-checkbox v-model="company.active" :value="0" :unchecked-value="1">
              Disable Company
            </b-checkbox>
          </b-col>
        </b-row>

        <hr>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Address Line 1"
              label-align="left"
            >
              <b-input v-model="address.address1"></b-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Address Line 2"
              label-align="left"
            >
              <b-input v-model="address.address2"></b-input>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="6"
          >
            <b-form-group
              label="City"
              label-align="left"
            >
              <b-input v-model="address.city"></b-input>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="State"
              label-align="left"
            >
              <b-input
                v-model="address.state"
                maxlength="2"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            lg="3"
          >
            <b-form-group
              label="Zipcode"
              label-align="left"
            >
              <b-input v-model="address.zipcode"></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
      <div v-if="errorMessage" class="text-danger text-center mt-2">{{ errorMessage }}</div>

      <template #modal-ok>
        <b-icon icon="check"></b-icon> Save
      </template>
      <template #modal-cancel>
        <b-icon icon="x"></b-icon> Cancel
      </template>
    </b-modal>

    <b-modal
      v-model="warningModal"
      title="Warning"
      ok-only
      ok-title="Understood"
      ok-variant="warning"
    >
      Please first add user to company to view on the On Boarding page.
    </b-modal>
  </div>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import OrganizationTypeAhead from '@/components/admin/OrganizationTypeAheadSelector.vue'

export default {
  props: {
    compId: {
      type: Number,
      default: 0
    },
    edit: {
      type: Boolean,
      default: false
    }
  },

  components: {
    OrganizationTypeAhead
  },

  data () {
    return {
      company: {
        organization: '',
        organization_id: null,
        name: null,
        industry_id: null,
        industryrevenuebucket_id: null,
        bookkeeper_id: null,
        dataentry_id: null,
        defaultdue: null,
        companypriority_id: 10,
        companytype_id: 10,
        companystatus_id: 10,
        active: 1
      },

      address: {
        id: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipcode: null
      },

      openModal: false,
      warningModal: false,
      loadCount: 0,
      bookkeepers: [],
      industries: [],
      buckets: [],
      statuses: [],
      priorities: [],
      frequencies: [],
      hasUser: false,
      dataentry: [],
      errorMessage: ''
    }
  },

  methods: {
    createCompany (bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.validateForm()) {
        this.$aimNotify.error(null, null, this.errorMessage)
        return false
      }
      const data = {
        id: this.compId,
        company: this.company,
        address: this.address
      }

      CompanyService.updateCompany(data).then(
        (response) => {
          this.$aimNotify.notify(response)

          this.$nextTick(() => {
            this.openModal = false
            this.$emit('refresh')
          })
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
      })
    },

    setOrganization (data) {
      if (data) {
        if (data.id) {
          this.company.organization_id = data.id
        }
        this.company.organization = data.name
      } else {
        this.company.organization_id = null
        this.company.organization = null
      }
    },

    init () {
      this.loadCount++
      CompanyService.init().then(
        (response) => {
          this.bookkeepers = []
          this.bookkeepers = response.data.info.bookkeepers

          this.dataentry = []
          this.dataentry = response.data.info.dataentry

          this.industries = []
          this.industries = response.data.info.industries

          this.buckets = []
          this.buckets = response.data.info.buckets

          this.statuses = []
          this.statuses = response.data.info.statuses

          this.priorities = []
          this.priorities = response.data.info.priority

          this.frequencies = []
          this.frequencies = response.data.info.frequencies
        }
      ).catch((err) => {
        this.$aimNotify.error(err.response)
      }).finally(() => { this.loadCount-- })

      if (this.compId > 0) {
        this.loadCount++
        CompanyService.getCompany(this.compId).then(
          (response) => {
            this.hasUser = response.data.info.users && response.data.info.users.length > 0

            const comp = response.data.info.company
            this.company = {
              name: comp.name,
              organization: comp.organization_name,
              organization_id: comp.organization_id,
              industry_id: comp.industry_id,
              industryrevenuebucket_id: comp.industryrevenuebucket_id,
              bookkeeper_id: comp.bookkeeper_id,
              dataentry_id: comp.dataentry_id,
              defaultdue: comp.defaultdue,
              companypriority_id: comp.companypriority_id,
              companytype_id: comp.companytype_id,
              companystatus_id: comp.companystatus_id,
              active: comp.active
            }

            this.setDayOfMonth()

            const address = response.data.info.address
            this.address = {
              id: address.id,
              address1: address.address1,
              address2: address.address2,
              city: address.city,
              state: address.state,
              zipcode: address.zipcode
            }
          }
        ).catch((err) => {
          this.$aimNotify.error(err.response)
        }).finally(() => { this.loadCount-- })
      } else {
        this.reset()
      }
    },

    reset () {
      this.company = {
        organization: '',
        organization_id: null,
        name: null,
        industry_id: null,
        industryrevenuebucket_id: null,
        bookkeeper_id: null,
        defaultdue: null,
        companypriority_id: 10,
        companytype_id: 10,
        companystatus_id: 10,
        active: 1
      }

      this.address = {
        id: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zipcode: null
      }
    },

    resetBucket () {
      if (!this.bucketsForIndustry.some(e => (+e.value === +this.company.industryrevenuebucket_id))) {
        this.company.industryrevenuebucket_id = null
      }
    },

    setDayOfMonth (event = null) {
      if (event === null && this.company.defaultdue === null) {
        let dom = null
        this.priorities.some(e => {
          if (e.id === this.company.companypriority_id) {
            dom = e.defaultdue
            return true
          }
          return false
        })
        this.company.defaultdue = dom
      } else {
        let dom = this.company.defaultdue
        this.priorities.some(e => {
          if (e.id === event) {
            dom = e.defaultdue
            return true
          }
          return false
        })
        this.company.defaultdue = dom
      }
    },

    validateForm () {
      if (!this.company.name || this.company.name === '') {
        this.errorMessage = 'Company Name is required.'
        return false
      }
      if (this.company.defaultdue !== null && (this.company.defaultdue < 2 || this.company.defaultdue > 28)) {
        this.errorMessage = 'Day of month Tickets Due must be between 2 and 28.'
        return false
      }
      this.errorMessage = ''
      return true
    },

    setErrorMessages () {
      if (!this.company.name || this.company.name === '') {
        this.errorMessage = 'Company Name is required.'
      } else if (this.company.defaultdue !== null && (this.company.defaultdue < 2 || this.company.defaultdue > 28)) {
        this.errorMessage = 'Day of month Tickets Due must be between 2 and 28.'
      } else {
        this.errorMessage = ''
      }
    }
  },

  computed: {
    /**
     * @returns {Boolean}
     */
    loading () {
      return this.loadCount > 0
    },

    bucketsForIndustry () {
      const buckets = []
      const defaultBuckets = []
      this.buckets.forEach(e => {
        if (+e.industry_id === +this.company.industry_id || +e.industry_id === 0) {
          e.buckets.forEach(b => {
            const minTxt = this.$convertToCurrency(b.min, false)
            const maxTxt = this.$convertToCurrency(b.max, false)
            if (+e.industry_id === 0) {
              defaultBuckets.push({ value: +b.id, text: (b.max ? minTxt + ' - ' + maxTxt : minTxt + '+') })
            } else {
              buckets.push({ value: +b.id, text: (b.max ? minTxt + ' - ' + maxTxt : minTxt + '+') })
            }
          })
        }
      })

      if (buckets.length === 0) {
        return defaultBuckets
      }
      return buckets
    },

    isSaveDisabled () {
      return !this.company.name || this.company.name === '' || (this.company.defaultdue !== null && (this.company.defaultdue < 2 || this.company.defaultdue > 28))
    }
  },

  watch: {
    'company.name': function () {
      this.setErrorMessages()
    },
    'company.defaultdue': function () {
      this.setErrorMessages()
    },
    'company.companystatus_id': {
      handler: function (newValue, oldValue) {
        if (newValue < 10 && !this.hasUser) {
          this.$nextTick(() => {
            this.warningModal = true
            this.$nextTick(() => {
              this.company.companystatus_id = this.oldValue
            })
          })
        }
      },
      immediate: false,
      deep: false
    }
  }

}
</script>
