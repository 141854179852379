<template>
  <div>
    <label class="form-label" for="organization-select" v-show="!hideLabel">
      <slot name="label">Organization</slot>
    </label>
    <vue-typeahead-bootstrap
      v-show="organizationId == null"
      :data="organizations"
      v-model="organizationSearch"
      :serializer="(s) => s.name"
      @hit="selectOrganization($event)"
      :inputClass="!isValid ? 'form-control is-invalid' : 'form-control'"
      inputName="organization-select"
      debounce="500"
      @input="createNewOrg()"
    ></vue-typeahead-bootstrap>
    <div class="input-group" v-if="organizationInfo">
      <span class="form-control alert-info">
        {{ organizationInfo.name }}
      </span>
      <div class="input-group-append">
        <button
          type="button"
          class="btn btn-outline-secondary"
          @click="clearOrganization()"
        >
          <b-icon icon="x"></b-icon>
        </button>
      </div>
    </div>
    <input type="hidden" v-model="organizationId" :required="required">
  </div>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

export default {
  components: {
    VueTypeaheadBootstrap
  },
  props: {
    selectedOrganizationId: {
      type: [Number, String],
      default () {
        return null
      }
    },
    hideLabel: {
      type: Boolean,
      default () {
        return false
      }
    },
    isValid: {
      type: Boolean,
      default () {
        return true
      }
    },
    required: {
      type: Boolean
    }
  },
  data () {
    return {
      organizations: [],
      organizationId: this.selectedOrganizationId,
      organizationSearch: ''
    }
  },
  computed: {
    /**
     * @returns {Object}
     */
    organizationInfo: function () {
      return this.organizations.find(x => (+x.id) === (+this.organizationId))
    }
  },
  methods: {
    /** Load all ATMS organizations used for type ahead
     * @returns {Promise}
     */
    getOrganizations: function () {
      CompanyService.getAllOrganizations().then(
        (response) => {
          this.organizations = response.data.info
        }
      )
    },
    /**
     * @returns {Void}
     */
    selectOrganization (organization) {
      this.organizationId = organization.id
      this.organizationSearch = ''
      this.$emit('selection', organization)
    },
    clearOrganization () {
      this.organizationId = null
      this.$emit('selection', null)
    },
    createNewOrg () {
      this.$emit('newEntry', { name: this.organizationSearch })
    }
  },
  mounted () {
    this.getOrganizations()
  },
  watch: {
    /**
     * If the bound selectedOrganizationId is changed
     * from the parent component, update our
     * internal selection
     */
    selectedOrganizationId (n, o) {
      this.organizationId = n
    }
  }
}
</script>
